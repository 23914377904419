<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>费用预警</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                            <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                                <a-select-option :value="0">我的</a-select-option>
                                <a-select-option :value="1">我下属的</a-select-option>
                            </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                                <template slot="title">
                                <span>剩余费用</span>
                                </template>
                                <a-input-group compact>
                                    <a-select style="width:100px" v-model="optionValue" @change="onChange">
                                        <a-select-option v-for="(item,index) in optionList" :key="index" :value="item.value">{{item.label}}</a-select-option>
                                    </a-select>
                                    <a-input-number style="width:100px" v-model="number" :min="0" @change="onChange" />
                                </a-input-group>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-tooltip placement="topLeft" >
                                <template slot="title">
                                    <span>所属校区</span>
                                </template>
                                <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear showSearch placeholder="请选择校区" :filterOption="filterOption" style="width: 220px;">
                                    <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                                </a-select>
                            </a-tooltip>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchList" :loading="loading" html-type="submit" type="primary">搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="middle" :pagination="false" :bordered='false' rowKey="contract_id"
                :columns="columns" :dataSource="list" :scroll="{ x: 1700 }" @change="handleChange">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="studentName" slot-scope="text, record">
                    <LUser :data="record"/>
                </template>
                <template slot="out_trade_no" slot-scope="text,record">
                    <a href="javascript:;">{{ text }}</a>
                </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '160px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '学员手机号', width: '110px', dataIndex: 'student_cellphone', key: 'student_cellphone' ,fixed: 'left'},
  { title: '签约合同号', width: '160px', dataIndex: 'contract_no', key: 'contract_no', scopedSlots: { customRender: 'out_trade_no' } ,fixed: 'left'},
  { title: '课程顾问', dataIndex: 'course_consultant', key: 'course_consultant',align:'center',},
  { title: '签约校区', dataIndex: 'studio_id', key: '3' ,align:'center',ellipsis: true},
  { title: '课程科目', dataIndex: 'course_cate_id', key: '5' },
  { title: '课程花费', dataIndex: 'contract_costs', key: '7' ,align:'center',sorter:()=>{}},
  { title: '优惠金额', dataIndex: 'contract_discount', key: '8' ,align:'center',sorter:()=>{}},
  { title: '签约课时', dataIndex: 'times', key: '10',align:'center' ,sorter:()=>{}},
  { title: '赠送课时', dataIndex: 'gift_times', key: '11' ,align:'center',sorter:()=>{}},
  { title: '有效期至', dataIndex: 'contract_end_date', key: 'contract_end_date' ,align:'center',sorter:()=>{}},
  { title: '合同类型', dataIndex: 'contract_type', key: '13' ,align:'center'},
  { title: '合同状态', dataIndex: 'contract_status', key: 'contract_status',align:'center' },
  { title: '经办人', dataIndex: 'created_by', key: '14' },
  { title: '操作时间', dataIndex: 'created_at', key: '15' ,sorter:()=>{}}
]
    import moment from 'moment'
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'costWarning',
        data() {
            return {
                columns,
                list: [],
                loading:false,
                exportLoading:false,
                optionList:[
                    {label:'不限',value:''},
                    {label:'小于',value:'<'},
                    {label:'小于等于',value:'<='},
                    {label:'大于',value:'>'},
                    {label:'大于等于',value:'>='},
                ],
                optionValue:'',
                number:'',
                studios:[]
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        mixins: [ tableMixins ],
        beforeCreate () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }
        },
        created () {
            this.getStudioList()
        },
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.is_sub = this.$ls.get('is_sub')
                await this.$store.dispatch('cContractReminderAction',{data:this.searchParams})
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            async getStudioList() {
                let res = await this.$store.dispatch('searchBelongStudioAction', { })
                this.studios = res.data
            },
            onChange(){
                if(this.optionValue && this.number){
                    this.searchParams.search.times = `${this.optionValue} ${this.number}`
                }else{
                    this.searchParams.search.times = undefined
                }
            },
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.searchParams.search.is_sub = e
                this.$forceUpdate()
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
            async toExport(){
                this.exportLoading = true
                let res = await this.$store.dispatch('cContractReminderExportAction', {search:this.searchParams.search})
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `费用预警.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>